import { getBrandForPage } from "@fwa/src/utils/pageUtils";
import { isBrowser } from "@fwa/src/utils/browserUtils";
import { buildQuery, getPathFromUrl } from "@fwa/src/utils/urlUtils";
import { FWA_BASE_URL, FWS_BASE_URL } from "@fwa/src/services/apiClient";
import { imageAbsoluteUrlFromImageType } from "@fwa/src/services/imageService";

import {
  type PageType,
  type FeedItemType,
  type FundraisingPageType,
  type TeamPageType,
  type SuperTeamPageType,
  type PartnerPageType,
  type FeedItemPostType,
  type FeedItemDonationRealType,
  type FeedItemDonationFakeType,
  type FeedItemDonationFacebookType,
  type FeedItemMilestoneType,
  type FeedItemActivityFitbitType,
  type FeedItemActivityStravaType,
  type BrandNameType,
  type ShareURLsObjectType,
  type ShareContentType,
  type FeedItemEntityTypesType,
  type PageEntityType,
  type ImageType,
  type InnerImageType,
} from "@fwa/src/types";

export const facebookMD5 = "26cae7718c32180a7a0f8e19d6d40a59";
export const twitterMD5 = "b73c2d22763d1ce2143a3755c1d0ad3a";
export const linkedinMD5 = "f1576406b382b7d1c8c2607f7c563d4f";

const isPage = (shareEntity: PageType | FeedItemType | null): boolean =>
  !shareEntity?.entityType
    ? false
    : ["FundraisingPage", "Team", "SuperTeam", "PartnerPage"].includes(
        shareEntity?.entityType || "",
      );

const getFacebookHeaderImageOrHeaderImageAbsolutePath = (
  image?: ImageType,
): string | undefined => {
  if (!image) return undefined;
  const facebookImage = image.childImages?.find((childImage: InnerImageType) =>
    childImage?.usage?.includes("headerImage/facebook"),
  );
  const foundImage = facebookImage || image;

  return imageAbsoluteUrlFromImageType(foundImage);
};

export const getShareContentFromEntity = (
  shareEntity: PageType | FeedItemType,
): ShareContentType => {
  const entityType: FeedItemEntityTypesType | PageEntityType =
    shareEntity?.entityType || null;

  // We might sharing a fundraising page or a team page but
  // we also might be sharing a feed item on team page,
  // the feed item url needs to know the original fundraising page url
  // so we cant just use the current url where the share button lives
  const entityUrl = !shareEntity
    ? ""
    : isPage(shareEntity) && "url" in shareEntity
      ? `${isBrowser ? window.origin : FWA_BASE_URL}${getPathFromUrl(
          shareEntity.url,
        )}`
      : "page" in shareEntity && !!shareEntity.page // feeditem.page
        ? `${isBrowser ? window.origin : FWA_BASE_URL}${getPathFromUrl(
            shareEntity.page.url,
          )}?feed=${shareEntity.uniqueId}`
        : "";

  const brandName: BrandNameType = isPage(shareEntity)
    ? getBrandForPage(shareEntity as PageType)
    : shareEntity && "page" in shareEntity
      ? getBrandForPage(shareEntity.page as PageType)
      : "cruk";

  const fundraisingPage = shareEntity as FundraisingPageType;
  const team = shareEntity as TeamPageType;
  const superTeam = shareEntity as SuperTeamPageType;
  const partnerPage = shareEntity as PartnerPageType;
  const donationReal = shareEntity as FeedItemDonationRealType;
  const donationFake = shareEntity as FeedItemDonationFakeType;
  const donationFacebook = shareEntity as FeedItemDonationFacebookType;
  const post = shareEntity as FeedItemPostType;
  const milestone = shareEntity as FeedItemMilestoneType;
  const activityStrava = shareEntity as FeedItemActivityStravaType;
  const activityFitbit = shareEntity as FeedItemActivityFitbitType;

  switch (entityType) {
    case "FundraisingPage":
      return {
        title: fundraisingPage.title,
        description:
          fundraisingPage.story ||
          fundraisingPage.fundraisingPageType?.story ||
          "",
        image:
          getFacebookHeaderImageOrHeaderImageAbsolutePath(
            fundraisingPage?.headerImage,
          ) ||
          getFacebookHeaderImageOrHeaderImageAbsolutePath(
            fundraisingPage.fundraisingPageType?.headerImage,
          ) ||
          "",
        emailUri: `mailto:${buildQuery({
          to: "",
          subject: `${fundraisingPage.fundraiser.forename}${
            fundraisingPage.fundraiser.surname
              ? ` ${fundraisingPage.fundraiser.surname}`
              : ""
          }'s Giving Page`,
          body: `Hi, \r\n \r\n Why not sponsor me by donating to ${
            brandName === "su2c" ? "Stand Up To Cancer" : "Cancer Research UK"
          } on the page ${entityUrl}`,
        })}`,
        entityUrl,
      };
    case "Team":
      return {
        title: team.title,
        description:
          team.story || team?.teamLeaderPage?.fundraisingPageType?.story || "",
        image:
          getFacebookHeaderImageOrHeaderImageAbsolutePath(team.headerImage) ||
          getFacebookHeaderImageOrHeaderImageAbsolutePath(
            team?.teamLeaderPage?.fundraisingPageType?.headerImage,
          ) ||
          "",
        emailUri: `mailto:${buildQuery({
          to: "",
          subject: `${team.title} fundraising team`,
          body: `Hi, \r\n \r\n Why not sponsor us by donating to ${
            brandName === "su2c" ? "Stand Up To Cancer" : "Cancer Research UK"
          } on the page ${entityUrl}`,
        })}`,
        entityUrl,
      };
    case "SuperTeam":
      return {
        title: superTeam.title,
        description:
          superTeam.story || superTeam?.fundraisingPageType?.story || "",
        image:
          getFacebookHeaderImageOrHeaderImageAbsolutePath(
            superTeam.headerImage,
          ) ||
          getFacebookHeaderImageOrHeaderImageAbsolutePath(
            superTeam.fundraisingPageType?.headerImage,
          ) ||
          "",
        emailUri: `mailto:${buildQuery({
          to: "",
          subject: `${superTeam.title} fundraising team`,
          body: `Hi, \r\n \r\n Why not sponsor us by donating to ${
            brandName === "su2c" ? "Stand Up To Cancer" : "Cancer Research UK"
          } on the page ${entityUrl}`,
        })}`,
        entityUrl,
      };
    case "PartnerPage":
      return {
        title: partnerPage.title,
        description: partnerPage.story || "",
        image:
          getFacebookHeaderImageOrHeaderImageAbsolutePath(
            partnerPage?.headerImage,
          ) || "",
        emailUri: `mailto:${buildQuery({
          to: "",
          subject: `${partnerPage.title} fundraising team`,
          body: `Hi, \r\n \r\n Why not sponsor us by donating to ${
            brandName === "su2c" ? "Stand Up To Cancer" : "Cancer Research UK"
          } on the page ${entityUrl}`,
        })}`,
        entityUrl,
      };
    case "DonationReal":
      return {
        title: `Donation${
          donationReal.amount ? ` of £${donationReal.amount}` : ""
        }${
          donationReal?.page?.title ? ` for ${donationReal?.page?.title}` : ""
        }${
          donationReal?.donationName ? ` from ${donationReal.donationName}` : ""
        }`,
        description: donationReal.donationMessage || "",
        image:
          getFacebookHeaderImageOrHeaderImageAbsolutePath(
            donationReal.page?.headerImage,
          ) ||
          getFacebookHeaderImageOrHeaderImageAbsolutePath(
            donationReal.page?.fundraisingPageType?.headerImage,
          ) ||
          "",
        emailUri: `mailto:${buildQuery({
          to: "",
          subject: donationReal?.page?.title || "",
          body: `${
            donationReal.donationMessage
              ? `${donationReal.donationMessage} \r\n\r\n `
              : ""
          }${entityUrl}`,
        })}`,
        entityUrl,
      };
    case "DonationFake":
      return {
        title: `Donation${
          donationFake.amount ? ` of £${donationFake.amount}` : ""
        }${donationFake.page.title ? ` for ${donationFake.page.title}` : ""}${
          donationFake?.donationName ? ` from ${donationFake.donationName}` : ""
        }`,
        description: donationFake.donationMessage || "",
        image:
          getFacebookHeaderImageOrHeaderImageAbsolutePath(
            donationFake.page?.headerImage,
          ) ||
          getFacebookHeaderImageOrHeaderImageAbsolutePath(
            donationFake.page?.fundraisingPageType?.headerImage,
          ) ||
          "",
        emailUri: `mailto:${buildQuery({
          to: "",
          subject: donationFake.page.title,
          body: `${
            donationFake.donationMessage
              ? `${donationFake.donationMessage} \r\n\r\n `
              : ""
          }${entityUrl}`,
        })}`,
        entityUrl,
      };
    case "DonationFacebook":
      return {
        title: `Donation${
          donationFacebook.amount ? ` of £${donationFacebook.amount}` : ""
        }${
          donationFacebook.page.title
            ? ` for ${donationFacebook.page.title}`
            : ""
        }${
          donationFacebook?.donationName
            ? ` from ${donationFacebook.donationName}`
            : ""
        }`,
        description: donationFacebook.donationMessage || "",
        image:
          getFacebookHeaderImageOrHeaderImageAbsolutePath(
            donationFacebook.page?.headerImage,
          ) ||
          getFacebookHeaderImageOrHeaderImageAbsolutePath(
            donationFacebook.page?.fundraisingPageType?.headerImage,
          ) ||
          "",
        emailUri: `mailto:${buildQuery({
          to: "",
          subject: donationFacebook.page.title,
          body: `${
            donationFacebook.donationMessage
              ? `${donationFacebook.donationMessage} \r\n\r\n `
              : ""
          }${entityUrl}`,
        })}`,
        entityUrl,
      };
    case "Post":
      return {
        title: `${
          post.galleryImages[0]?.image?.title
            ? post.galleryImages[0].image.title
            : `A post ${post.page.title ? `from ${post.page.title}` : ""}`
        }`,
        description: post.body || "",
        image:
          imageAbsoluteUrlFromImageType(post.galleryImages[0]?.image) ||
          getFacebookHeaderImageOrHeaderImageAbsolutePath(
            post.page?.headerImage,
          ) ||
          getFacebookHeaderImageOrHeaderImageAbsolutePath(
            post.page?.fundraisingPageType?.headerImage,
          ) ||
          "",
        emailUri: `mailto:${buildQuery({
          to: "",
          subject: post.page.title,
          body: `${
            post.body ? `${post.body ? `${post.body} \r\n\r\n ` : ""}` : ""
          }${entityUrl}`,
        })}`,
        entityUrl,
      };
    case "Milestone":
      return {
        title:
          milestone.body || milestone.page.title
            ? `Milestone ${milestone.page.title}`
            : "",
        description: "",
        image:
          milestone.shareBackgroundImage?.url ||
          getFacebookHeaderImageOrHeaderImageAbsolutePath(
            milestone.page?.headerImage,
          ) ||
          getFacebookHeaderImageOrHeaderImageAbsolutePath(
            milestone.page?.fundraisingPageType?.headerImage,
          ) ||
          "",
        emailUri: `mailto:${buildQuery({
          to: "",
          subject: milestone.page.title,
          body: `${
            milestone.body
              ? `${milestone.body ? `${milestone.body} \r\n\r\n ` : ""}`
              : ""
          }${entityUrl}`,
        })}`,
        entityUrl,
      };
    case "ActivityStrava":
      return {
        title: activityStrava.name || "Strava Activity",
        description: `${
          activityStrava.distance ? `${activityStrava.distance} km` : ""
        }${
          activityStrava.stravaActivityType
            ? ` ${activityStrava.stravaActivityType}`
            : ""
        }`,
        image:
          activityStrava.map?.imageUrl ||
          getFacebookHeaderImageOrHeaderImageAbsolutePath(
            activityStrava.page?.headerImage,
          ) ||
          getFacebookHeaderImageOrHeaderImageAbsolutePath(
            activityStrava.page?.fundraisingPageType?.headerImage,
          ) ||
          "",
        emailUri: `mailto:${buildQuery({
          to: "",
          subject: activityStrava.page.title,
          body: `${
            activityStrava.name
              ? `${
                  activityStrava.name ? `${activityStrava.name} \r\n\r\n ` : ""
                }`
              : ""
          }${entityUrl}`,
        })}`,
        entityUrl,
      };
    case "ActivityFitbit":
      return {
        title: `Fitbit Activity`,
        description: `${
          activityFitbit.page?.owner?.displayName ||
          activityFitbit.page.fundraiser.forename
        } walked ${activityFitbit.steps} steps to help beat cancer`,
        image:
          getFacebookHeaderImageOrHeaderImageAbsolutePath(
            activityFitbit.page?.headerImage,
          ) ||
          getFacebookHeaderImageOrHeaderImageAbsolutePath(
            activityFitbit.page?.fundraisingPageType?.headerImage,
          ) ||
          "",
        emailUri: `mailto:${buildQuery({
          to: "",
          subject: activityFitbit.page.title,
          body: `I've walked ${activityFitbit.steps} steps to help beat cancer. \r\n\r\n ${entityUrl}?`,
        })}`,
        entityUrl,
      };
    default:
      return {
        title: "",
        description: "",
        image: "",
        emailUri: "",
        entityUrl,
      };
  }
};

export const getShareURLsFromEntity = (
  shareEntity: PageType | FeedItemType,
): ShareURLsObjectType => {
  const route = isPage(shareEntity) ? "pages" : "feeditems";
  const { uniqueId } = shareEntity;
  const { description, emailUri, entityUrl } =
    getShareContentFromEntity(shareEntity);

  const whatsappUri = `whatsapp://send${buildQuery({
    text: `${description} ${entityUrl}`,
  })}`;
  const facebookMessengerUri = `fb-messenger://share${buildQuery({
    link: entityUrl,
  })}`;
  const smsUri = `sms:${buildQuery({
    body: entityUrl,
  })}`;

  return {
    facebook: `${FWS_BASE_URL}/${route}/${uniqueId}/s/${facebookMD5}`,
    twitter: `${FWS_BASE_URL}/${route}/${uniqueId}/s/${twitterMD5}`,
    linkedIn: `${FWS_BASE_URL}/${route}/${uniqueId}/s/${linkedinMD5}`,
    whatsapp: `${whatsappUri}`,
    sms: `${smsUri}`,
    email: `${emailUri}`,
    fbmessenger: `${facebookMessengerUri}`,
    copy: `${entityUrl}`,
  };
};
